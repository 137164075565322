import React from 'react';
import Header from './header';
import Footer from './footer';

import '../assets/styles/layout.css';
import '../assets/fonts/panchan/css/panchang.css';
import * as styles from './layout.module.css';

const Layout = ({ children, companyInfo, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <Footer companyInfo={companyInfo} />
  </>
)

export default Layout
