import React from 'react';

import * as styles from './footer.module.css';

const Footer = ({ companyInfo }) => (
  <footer className={styles.footer}>
    <div className={styles.footerWrapper}>
      <div className={styles.companyAddress}>
        {companyInfo && (
          <div>
            {companyInfo.name}
            <br />
            {companyInfo.address1}
            <br />
            {companyInfo.address2 && (
              <span>
                {companyInfo.address2}
                <br />
              </span>
            )}
            {companyInfo.zipCode} {companyInfo.city}
            {companyInfo.country && <span>, {companyInfo.country}</span>}
          </div>
        )}
      </div>

      <div className={styles.siteInfo}>
        © {new Date().getFullYear()}, Built by <a href='https://kaesve.nl'>Studio Adek</a>
      </div>
    </div>
  </footer>
);

export default Footer;